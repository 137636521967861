import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import { Box } from 'reflexbox'
import ReactPlayer from 'react-player'

import { Primary, Alternate, Secondary } from '../../components/Section'
import Space from '../Space'

import { HrefButtonGhost } from '../Button'
import LogoCdc from '../../images/logo-cdc-transparent.png'
import AboutJoynerFmd from '../../images/about-joyner-fmd.jpg'
import { calculateRem } from '../../utils/javascript-styles'
import LogoCmc from '../../images/csm-logo.png'

const StyledLogoCdc = styled.img`
  width: ${calculateRem(400)};
`
const StyledJoynerFmd = styled.img``

const AboutUs = () => {
  return (
    <section>
      <Secondary centered>
        <Row>
          <Col xs={12}>
            <h1>CDC Certified Divorce Coach®</h1>
            <p>
              A <strong>CDC Certified Divorce Coach®</strong> adheres to a Code
              of Ethics and Practice Standards to support you in a professional
              way. A divorce coach helps you think through everyday decisions as
              well as your best outcome in child custody, co-parenting, and
              alimony; helps you to explore the options for going through the
              divorce process and how to engage a divorce attorney. Plus many
              other ways to help you create a favorable outcome in your divorce.
            </p>
            <HrefButtonGhost
              href="https://certifieddivorcecoach.com/find-a-divorce-coach/?state=Arizona+&um_search=1"
              target="_blank"
            >
              Learn More
            </HrefButtonGhost>
            <Space mb={30} />
            <StyledLogoCdc src={LogoCdc} alt="certified divorce coach logo" />
          </Col>
        </Row>
      </Secondary>
      <Alternate>
        <Row>
          <Col xs={12} md={6}>
            <h3>
              Michael S. Joyner, MD Founder & President MJMD Mediation &
              Coaching Certified Divorce Coach®
            </h3>
            <p>
              Dr. Michael J is your coach during this process. He is the author
              of Forever My Daddy: Denied. His passion and dream to be a father
              to his two sons was crushed as a result of Parental Alienation. As
              a result of this he is not only a Hand Surgeon, inventor and CEO
              of the company MJ MD Global Management, he is a certified divorce
              coach, who does not want You to go through what he went through.
              We use his book as a guide in the coaching process.
            </p>
          </Col>
          <Col xs={12} md={6}>
            <StyledJoynerFmd
              src={AboutJoynerFmd}
              alt="michale joyner holding fmd billboard"
            />
          </Col>
        </Row>
      </Alternate>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <StyledJoynerFmd
              src={LogoCmc}
              alt="condition sports management logo"
            />
            <h3>Condition Sports Management</h3>
            <p>
              Condition Sports Management was founded by Michael S. Joyner, MD.
              Dr. Joyner is a hand surgeon, author of Forever My Daddy: Denied,
              and founder of the Forever My Daddy Grand Foundation. Dr. Michael
              J’s experience helping people with asset protection, personal
              coaching, and mediation, was the driving force for him to create
              Condition Sports Management. CSM is a new agency that is dedicated
              to helping young athletes progress through their career from
              college to their professional level and even post-career
              transition. CSM is here to help its clients maximize their earning
              potential at every stage.
            </p>
            <Box sx={{ display: `flex`, justifyContent: `center` }}>
              <ReactPlayer url="http://youtu.be/zMY_v1PGsHI?hd=1" />
            </Box>
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default AboutUs
