import React from 'react'
import AboutUs from '../components/AboutUs'
import MainLayout from '../components/MainLayout'

const AboutUsPage = () => {
  return (
    <MainLayout>
      <AboutUs />
    </MainLayout>
  )
}

export default AboutUsPage
