import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { calculateRem } from "../../utils/javascript-styles"

const StyledSpace = styled.div`
  display: ${props =>
    props.display === "inline" ? "inline-block" : props.display};
  height: ${calculateRem(1)};
  margin-top: ${props => calculateRem(props.mt)};
  margin-right: ${props => calculateRem(props.mr)};
  margin-bottom: ${props => calculateRem(props.mb)};
  margin-left: ${props => calculateRem(props.ml)};
`

const Space = ({ mt, mb, mr, ml, display }) => {
  return <StyledSpace display={display} mt={mt} mb={mb} mr={mr} ml={ml} />
}

Space.defaultProps = {
  display: "block",
  mt: 1,
  mb: 1,
  mr: 1,
  ml: 1,
}

Space.propTypes = {
  display: PropTypes.string,
  mt: PropTypes.number,
  mr: PropTypes.number,
  mb: PropTypes.number,
  ml: PropTypes.number,
}

export default Space
